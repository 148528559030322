body {
  font-family: "Poppins",sans-serif;
  line-height: 2;
  color: #515666;
}

footer{
  background-color: #202020 !important;
}

a {
  -moz-transition-property: color;
  -o-transition-property: color;
  -webkit-transition-property: color;
  transition-property: color;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  color: #3a3f4c;
}

a, a:hover, a:active, a:focus {
  outline: 0;
}

a:hover, a:active {
  color: #3db166 !important;
}

ul, ol {
list-style: none;
}

.header .navbar-wrapper {
  background: #ffffff;
}

.topbar {
  background-color: #114a82;
  color: #fff;
}

.topbar > a {
  color: #fff;
}

.navbar-topbar-a {
  color: #fff;
  font-size: medium !important;
  text-decoration: none;
}

a.after-icon-header {
  padding-left: 5px;
  font-size: large;
  color: #f3f3f3;
  text-decoration: none;
}

.icon-header{
  font-size: medium !important;
}

.icon-menu {
  display: none;
}

.navbar-default {
  background-color: orange !important;
}

.pull-right {
  float: right!important;
}

.main-menu .navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}
.main-menu .navbar-nav > li {
  position: relative;
  z-index: 2;
  display: inline-block;
}
.main-menu .navbar-nav > li .fa-angle-down {
  display: none;
}
.main-menu .navbar-nav li > a {
  font-size: 18px;
  font-weight: 500;
  font-family: 'Exo', sans-serif;
  color: #333333;
  line-height: 128px;
  margin-left: 18px;
  margin-right: 18px;
}
.main-menu .navbar-nav li > .sub-menu {
  position: absolute;
  z-index: 2;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 1px solid #e5e5f8;
  min-width: 220px;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
}
.main-menu .navbar-nav li:hover .sub-menu {
  top: 128px;
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
}
.main-menu .navbar-nav li > .sub-menu li {
  float: none;
  position: relative;
  padding: 0 20px;
  border-bottom: 1px solid #e5e5f8;
}
.main-menu .navbar-nav li > .sub-menu li a {
  background: none;
  color: #2f323d;
  text-transform: none;
  font-weight: 400;
  line-height: 24px;
  display: block;
  position: relative;
  padding: 7px 0;
  -webkit-transition: all 0.1s ease 0s;
  -o-transition: all 0.1s ease 0s;
  transition: all 0.1s ease 0s;
  text-decoration: none;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.main-menu .navbar-nav li > .sub-menu li .sub-menu {
  list-style: none;
  padding: 10px 0;
  margin: 0;
  position: absolute;
  left: 70%;
  top: 0;
  z-index: 1;
  min-width: 220px;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: #ffffff;
}
.nav-collapse .navbar-nav > li > a {
  color: #ffffff !important;
}

.bd-header {
  grid-column: 1 / span 3;
}

.bg-orange {
  background-color: #FFA500 !important;
}

.nav-link {
  font-size: large !important;
  font-weight: bolder;
  color: #193059 !important;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.sub-menu li a {
  background: none;
  color: #2f323d;
  text-transform: none;
  font-weight: 400;
  line-height: 24px;
  display: block;
  position: relative;
  padding: 7px 10px;
  -webkit-transition: all 0.1s ease 0s;
  -o-transition: all 0.1s ease 0s;
  transition: all 0.1s ease 0s;
  text-decoration: none;
  font-size: medium !important;
}

.site{
  background: linear-gradient(90deg, rgba(22, 161, 96, 0.903) 0%, rgba(25, 186, 39, 0.769) 50%, rgba(23, 74, 20, 0.576) 100%);
}

#big-box {
background: #fff;
  border: 1px solid #e5e5f8;
  min-height: 79vh;
}

.box-title {
  color: #3a3f4c;
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: 1px solid #e5e5f8;
  padding: 20px 0 25px;
  margin: 0 15px 30px;
}

.box-subtitle {
  color: #3a3f4c;
  line-height: 24px;
  margin: 0 30px 30px;

}

.box-p {
  color: #3a3f4c;
  line-height: 24px;
  margin: 0 15px 15px;

}

.form-control.required .form-label:after {
  content:"*";
  color:red;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  background-color: #3db166 !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

.glass{
  height: 30px !important;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

.divider:after,
  .divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
  }
  .h-custom {
    height: calc(100% - 73px);
  }
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.jumbotron-area-conocimiento {
  color: white;
  height: 60vh;
  background: 
  linear-gradient(
    rgba(255, 255, 255, 0.467), 
    rgba(0, 0, 0, 0.732),
    rgba(0, 0, 0, 0.942)
  ),
  url("./assets/images/pizarron-scaled.jpg");
  width:100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
}

.jumbotron-cursos {
  color: white;
  height: 60vh;
  background: 
  linear-gradient(
    rgba(255, 255, 255, 0.467), 
    rgba(0, 0, 0, 0.732),
    rgba(0, 0, 0, 0.942)
  ),
  url("./assets/images/alumnos.jpg");
  width:100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
}

.jumbotron-whoweare {
  color: white;
  height: 60vh;
  background: 
  linear-gradient(
    rgba(255, 255, 255, 0.467), 
    rgba(0, 0, 0, 0.596),
    rgba(0, 0, 0, 0.813)
  ),
  url("./assets/images/whoweare.jpg");
  width:100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.lead{
  color: #3DB166;
}

.divider-green{
  border-bottom: #3db166 4px;
}

.title-two-columns{
  font-weight: bold;
}

.mv-wrapper{
  background-color: #192f59;
  width: 100 wh;
}
.mv-title{
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  text-transform: none;
  color: #3db166;
}

.titlecolumnsimg{
  background-color: #e6e6e6; 
}

.carousel-item {
  height: 75vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: rgba(245, 246, 252, 0.52), rgba(19, 117, 60, 0.73);
}

.img-fluid-title-columns{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-fluid-background{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon-quote{
  font-size: 170px;
  height: 82px;
  color: #3DB166;
}

.card {
  transition: transform 0.2s ease;
  box-shadow: 0 4px 6px 0 rgba(22, 22, 26, 0.18);
  border-radius: 0;
  border: 0;
  margin-bottom: 1.5em;
  cursor: pointer;
}
.card:hover {
  transform: scale(1.1);
}

.col > a{
  text-decoration: none;
}

.course-desc{
  font-size: 20px;
}

.nav-admin-bar > .nav-link{
  color: #FFFFFF !important;
}

.nav-admin-bar > .nav-link:hover{
  color: #f3f3f3 !important;
}

.img-publication {
  width: 80%;
  max-height: 550px !important;
  object-fit: cover; /* or 'contain' if you want stretch instead of crop */
}

.link-publication{
  text-decoration: none;
}

#carouselCedoc{
  background: linear-gradient(180deg, rgba(255,255,255,0.76) 0%, rgba(26,143,65,0.5480567226890756) 100%);
}